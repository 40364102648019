import jQuery from 'jquery';
import Cookies from 'js-cookie';
import focusWithin from 'focus-within';
import { cookie_consent } from './cookie-consent';
import { header_functionality } from './header';
import { popups } from './popups';
import { slick_sliders } from './slick';

jQuery(document).ready(function($) {
	focusWithin(document);

	if (cookie_consent.cookie === undefined) {
		cookie_consent.load();
		cookie_consent.setcookie();
	}

	header_functionality.mobile_nav();
	header_functionality.stickyclass();

	slick_sliders.home_clients_carousel();
	slick_sliders.home_awards_carousel();

	popups.newsletter_signup_popup();
	popups.file_download_popup();

	// Hero Video Control
	$('.video-control').on('click', function(e) {
		e.preventDefault();

		var video = $(this).siblings('video')[0];

		if ( $(this).hasClass('pause') ) {
			if ( video !== undefined ) {
				video.pause();
				$(this).removeClass('pause');
				$(this).addClass('play');
// 				$(this).children('.play-pause').text('play_arrow');
			}
		} else {
			if ( video !== undefined ) {
				video.play();
				$(this).removeClass('play');
				$(this).addClass('pause');
// 				$(this).children('.play-pause').text('pause');
			}
		}
	});

	// Accordion
	$('.accordion-wrapper h3 button.trigger').on('click', function(e) {
		var expanded = $(this).attr('aria-expanded'),
			controls = $(this).attr('aria-controls'),
			other_panels = $(this).parents('.accordion-wrapper').find('.accordion.panel').not('#' + controls),
			other_triggers = $(this).parents('.accordion-wrapper').find('h3 button.trigger').not(this);

		if (expanded === 'true') {
			$('#' + controls).slideUp();
			$(this).removeClass('open');
			$(this).attr('aria-expanded', 'false');
// 			$(this).children('.trigger-icon').text('expand_more');
		} else {
			$(this).attr('aria-expanded', 'true');
			$(this).addClass('open');
			other_panels.slideUp();
			other_triggers.removeClass('open');
			other_triggers.attr('aria-expanded', 'false');
// 			$(this).children('.trigger-icon').text('expand_less');
			$('#' + controls).slideDown();
		}

		$.each( other_triggers, function(i, v) {
			if ($(v).hasClass('open')) {
// 				$(v).children('.trigger-icon').text('expand_less');
			} else {
// 				$(v).children('.trigger-icon').text('expand_more');
			}
		});
	});

	$('.accordion-wrapper h3 button.trigger').on('keydown', function(e) {
	    if (e.keyCode === 38 || e.keyCode === 40) {
	    	e.preventDefault();

	        var triggers = $(this).parents('.accordion-wrapper').find('h3 button.trigger'),
	        	current = triggers.index(this),
	        	previous = current - 1,
	        	next = current + 1;

	        if (e.keyCode === 38) {
	        	if (previous === -1) {
	        		previous = triggers.length - 1;
	        	}

	        	triggers[previous].focus();
	        }

	        if (e.keyCode === 40) {
	        	if (next === triggers.length) {
	        		next = 0;
	        	}

	        	triggers[next].focus();
	        }
	    }
	});

	// Category Filter
	$('.filter-heading').on('click', function(e) {
		e.stopPropagation();

		var list = $(this).siblings('.filter-list');
		var filter_icon = $(this).children('.filter-icon');

		if ( list.hasClass('open') ) {
			list.removeClass('open');
// 			filter_icon.text('expand_more');
		} else {
			list.addClass('open');
// 			filter_icon.text('expand_less');
		}

		if ( $(this).hasClass('open') ) {
			$(this).removeClass('open');
		} else {
			$(this).addClass('open');
		}

	});

	$('body').on('click', function(e) {
		var list = $('.filter').find('.filter-list');
		var filter_icon = $('#archive-filters .filter-icon');

		if ( list.hasClass('open') ) {
			list.removeClass('open');
			filter_icon.text('expand_more');
		}

		if ( $('.filter-heading').hasClass('open') ) {
			$('.filter-heading').removeClass('open');
		}
	});


	$('.filter-heading').on('focus', function(e) {
		e.stopPropagation();

		$('.filter-heading').on('keydown', function(e) {
			e.stopPropagation();
			if (e.keyCode === 13) {

			var list = $(this).siblings('.filter-list');
			var filter_icon = $(this).children('.filter-icon');


			if ( list.hasClass('open') ) {
				list.removeClass('open');
				filter_icon.text('expand_more');
				} else {
					list.addClass('open');
					filter_icon.text('expand_less');
				}

			if ( $(this).hasClass('open') ) {
				$(this).removeClass('open');
				} else {
					$(this).addClass('open');
				}

			}
		});
	});


	$('.search-button-toggle').on('click',function(){
		$('#search_box').toggle();
		return false;
	});

	$('#search_box #close').on('click',function(){
		$('#search_box').hide();
		return false;
	});


	// var scroll_to_form = function() {
	// 	$([document.documentElement, document.body]).animate({
	// 				scrollTop: $('#popup-enquiry-form').offset().top
	// 		}, 500);
	// 	}
  //
	// 	$('.file-download-popup').click( scroll_to_form );

		});
