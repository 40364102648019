import 'slick-carousel';

var $ = jQuery;

export var slick_sliders = {

  home_clients_carousel: function() {

    // Services Slider
    if ( $('#home-clients-carousel').length === 1 ) {

        $('#home-clients-carousel').slick({
          mobileFirst: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          speed: 500,
          infinite: true,
          autoplay: false,
          responsive: [
              {
                breakpoint: 600,
                settings: 'unslick'
              }
              ]
        });
      }

      $(window).on('resize', function() {
          $('.carousel').slick('resize');
      });

  },

  home_awards_carousel: function() {

    // Services Slider
    if ( $('#home-awards-carousel').length === 1 ) {

        $('#home-awards-carousel').slick({
          mobileFirst: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          speed: 1500,
          infinite: true,
          autoplay: false,
          responsive: [
              {
                breakpoint: 600,
                settings: 'unslick'
              }
              ]
        });
      }

      $(window).on('resize', function() {
          $('.carousel').slick('resize');
      });

  }


}
