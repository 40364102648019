import Cookies from 'js-cookie';
import { dbdutil_get_cookie_domain } from './utils';

export var cookie_consent = {
  load: function() {
    //  ajax call to return html for cookie popup

    var data = {};
    var html;

    jQuery.ajax({
      method: "GET",
      url: "/wp-json/dbdstart/v1/cookie-consent/",
      data: data,
      success: function(response) {
       if ( response.success ) {
           html = response.cookie_popup;
           jQuery('body').prepend(html);
         }
       }
    });
  },

  setcookie: function() {
    // function to set cookie once popup dismissed

  	jQuery('body').on('click', '#accept-cookies', function(e) {
  		e.preventDefault();
  		var COOKIES_CONSENT = {
  			set: true
  		};

      var domain = dbdutil_get_cookie_domain();
  		Cookies.set('COOKIES_CONSENT', COOKIES_CONSENT, { domain: domain, expires: 90 });
  		jQuery('#cookie-consent-popup-wrapper').removeClass('showing');

  	});

  },
  cookie: Cookies.getJSON('COOKIES_CONSENT')

}
