var $ = jQuery;

export var header_functionality = {
  stickyclass: function() {

    // Add class to header when user scrolls down
    $(window).scroll(function() {
      var scroll = $(window).scrollTop();
      if (scroll >= 100) {
          $('#header-wrapper').addClass('scrolled');
          $('#scroll-to-top').addClass('active');

      }
      else {
        $('#header-wrapper').removeClass('scrolled');
        $('#scroll-to-top').removeClass('active');
      }
    });

    // const scroll = new LocomotiveScroll({
    //       el: document.querySelector('[data-scroll-container]'),
    //       smooth: true,
    //       smoothMobile: true,
    //       getSpeed: true,
    //       getDirection: true,
    //       useKeyboard: true
    //   });

    // scroll.on('scroll', (position) => {
    //     if ((position.scroll.y) > 100) {
    //       document.querySelector('#header-wrapper').classList.add('scrolled');
    //       document.querySelector('#scroll-to-top').classList.add('active');
    //     } else {
    //       document.querySelector('#header-wrapper').classList.remove('scrolled');
    //       document.querySelector('#scroll-to-top').classList.remove('active');
    //     }
    //   });

    const target = document.querySelector('#main-content');

      $('#scroll-to-top').on('click', function(e) {
    		e.stopPropagation();
        //console.log('clicked');
        //console.log(target);
        scroll.scrollTo(target);
    	});


  },

  mobile_nav: function() {
    //  load event handlers for navigation in mobile view

    var menu_children_count = $("#main-navigation li ul.sub-menu li").length;
    //console.log(menu_children_count);
    //console.log('hello there');

    // DOM ELEMENTS
    $('#main-navigation li ul.sub-menu').each(function(index, value) {
      //console.log(value);
      var count = $(value).children().length;
      //console.log(count);
      if ( count > 7 ) {
        $(this).addClass('two-col');
      }
    });


    var menu_icon = $('.menu-icon');
    var contact_icon = $('.contact-menu-icon');
    var main_navigation = $('#main-navigation');
    var contact_details = $('#mobile-contact-details');

    // Navbar Toggle
    $('#contact-toggle').on('click', function() {
      $('#mobile-contact-details').toggleClass('menu-open');
       contact_icon.toggleClass('menu-open');
/*
       if ( contact_icon.hasClass('menu-open') ) {
         contact_icon.text('close');
       } else {
         contact_icon.text('call');
       }
*/
       if ( menu_icon.hasClass('menu-open') ) {
         menu_icon.toggleClass('menu-open');
         menu_icon.text('menu');
         main_navigation.removeClass('menu-open');
       }

    });

    // Navbar Toggle
		$('#nav-toggle').on('click', function() {
			$('#main-navigation').toggleClass('menu-open');
			 menu_icon.toggleClass('menu-open');
/*
			 if ( menu_icon.hasClass('menu-open') ) {
				 menu_icon.text('close');
			 } else {
				 menu_icon.text('menu');
			 }
*/
       if ( contact_icon.hasClass('menu-open') ) {
         contact_icon.toggleClass('menu-open');
         contact_icon.text('call');
         contact_details.removeClass('menu-open');
       }
		});

    var rtime;
    var timeout = false;
    var delta = 200;
    $(window).resize(function() {
        rtime = new Date();
        if (timeout === false) {
            setTimeout(resizeend, delta);
        }
    });

    var disable_event = false;
    var width = $(window).width();
    if (width <= 1199) {
      disable_event = true;
    }
    else {
      disable_event = false;
    }

    function resizeend() {

        //console.log(disable_event);
        if (new Date() - rtime < delta) {
            setTimeout(resizeend, delta);
        } else {
            timeout = false;
            //console.log("Done resizing");
            var mobile = $(window).width();
            if ( mobile <= 1199 ){
              //console.log("We are in mobile town");
              disable_event = true;
              //console.log(disable_event);
            }
            else {
              //console.log("Done resizing");
              //console.log("We are in desktop town");
              disable_event = false;
              //console.log(disable_event);
            }
        }
    }

  	// Add/remove class for mobile menu sub-items
  	$('#main-navigation li.menu-item-has-children > a').on('click', function(e) {
      // Only disable element if disable_event set to true i.e. mobile menu visible.
      if (disable_event === true) {
        e.preventDefault();
      }
  	  var submenu = $(this).siblings('.sub-menu');
  	  var submenu_parent = $(this).parent('.menu-item-has-children');
  	  submenu.toggleClass('open');
  	  submenu_parent.toggleClass('open');
  	});

  }
}
