var $ = jQuery;

export var popups = {
  newsletter_signup_popup: function() {
    //  load event handlers for popups

    // popup form controls
    $('#newsletter-popup').on('click', function() {
      console.log('clicked');
      $(this).addClass('active');
      $('#popup-overlay').addClass('showing');
      $('#popup-wrapper').addClass('show');
      $('#popup-enquiry-form').attr('aria-hidden', 'false');
    });

    $('#newsletter-popup').on('keydown', function(e) {
        if (e.keyCode === 9 && $(this).hasClass('active') ) {
          e.preventDefault();
          $('#popup-wrapper #input_4_1').focus();
        }
    });

    $(document).on('keydown', function(e) {
      if (e.keyCode === 27) {
        e.preventDefault();
        $('#newsletter-popup').removeClass('active');
        $('#popup-overlay').removeClass('showing');
        $('#popup-wrapper').removeClass('show');
        $('#popup-enquiry-form').attr('aria-hidden', 'true');
      }
    });

    $('.close-popup').on('click', function() {
      $('#newsletter-popup').removeClass('active');
      $('#popup-overlay').removeClass('showing');
      $('#popup-wrapper').removeClass('show');
      $('#popup-enquiry-form').attr('aria-hidden', 'true');
    });

    $('#popup-overlay').on('click', function() {
      $(this).removeClass('showing');
      $('#newsletter-popup').removeClass('active');
      $('#popup-wrapper').removeClass('show');
      $('#popup-enquiry-form').attr('aria-hidden', 'true');
    });

	},

  file_download_popup: function() {
    //  load event handlers for popups

    // popup form controls
    $('.file-download-popup').on('click', function() {
      console.log('clicked');
      $(this).addClass('active');
      $('#popup-overlay').addClass('showing');
      $('#popup-wrapper').addClass('show');
      $('#popup-enquiry-form').attr('aria-hidden', 'false');
    });

    $('.file-download-popup').on('keydown', function(e) {
        if (e.keyCode === 9 && $(this).hasClass('active') ) {
          e.preventDefault();
          $('#popup-wrapper #input_4_1').focus();
        }
    });

    $(document).on('keydown', function(e) {
      if (e.keyCode === 27) {
        e.preventDefault();
        $('.file-download-popup').removeClass('active');
        $('#popup-overlay').removeClass('showing');
        $('#popup-wrapper').removeClass('show');
        $('#popup-enquiry-form').attr('aria-hidden', 'true');
      }
    });

    $('.close-popup').on('click', function() {
      $('.file-download-popup').removeClass('active');
      $('#popup-overlay').removeClass('showing');
      $('#popup-wrapper').removeClass('show');
      $('#popup-enquiry-form').attr('aria-hidden', 'true');
    });

    $('#popup-overlay').on('click', function() {
      $(this).removeClass('showing');
      $('.file-download-popup').removeClass('active');
      $('#popup-wrapper').removeClass('show');
      $('#popup-enquiry-form').attr('aria-hidden', 'true');
    });

	}

}
